import i18n from "@/core/plugins/i18n";
const t = i18n.global.t;

export const occupations = [
  {
    label: t("fields.companyEmployee"),
    value: "company-employee",
  },
  {
    label: t("fields.companyOfficer"),
    value: "company-officer",
  },
  {
    label: t("fields.civilServant"),
    value: "civil-servant",
  },
  {
    label: t("fields.selfEmployed"),
    value: "self-employed",
  },
  {
    label: t("fields.houseWifeHouseHusband"),
    value: "housewife-househusband",
  },

  {
    label: t("fields.contractEmployee"),
    value: "contract-employee",
  },
];
