<template>
  <div
    class="d-flex align-items-center justicy-content-center"
    style="height: 100%"
  >
    <div class="d-flex align-items-center">
      <div class="me-7">
        <img src="/images/walletSuccess.png" alt="" />
      </div>
      <div>
        <h4 class="mb-2">{{ $t("tip.orderCreated") }}</h4>
        <div>
          {{ $t("tip.withdrawSuccessTip") }}
        </div>
      </div>
    </div>
  </div>
</template>
