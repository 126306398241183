type Address = {
  k: number; // region id
  region: string; // region name
  l: string; // locality
  m: string; // street address
  o: string; // extended address
};

type AddressResponse = {
  [key: string]: [string, string, string, string];
};

const YUBINBANGO_URL = "https://yubinbango.github.io/yubinbango-data/data";
const regions = [
  null,
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

const postalCodeData: {
  [postalCode: string]: [number, string, string, string?];
} = {
  "1000000": [13, "千代田区", ""],
  "1000001": [13, "千代田区", "千代田"],
  "1000002": [13, "千代田区", "皇居外苑"],
  "1000003": [13, "千代田区", "一ツ橋"],
  "1000004": [13, "千代田区", "大手町"],
  "1000005": [13, "千代田区", "丸の内"],
  "1000006": [13, "千代田区", "有楽町"],
  "1000011": [13, "千代田区", "内幸町"],
  "1000012": [13, "千代田区", "日比谷公園"],
  "1000013": [13, "千代田区", "霞が関"],
  "1000014": [13, "千代田区", "永田町"],
  "1000100": [13, "大島町", ""],
  "1000101": [13, "大島町", "元町"],
  "1000102": [13, "大島町", "岡田"],
  "1000103": [13, "大島町", "泉津"],
  "1000104": [13, "大島町", "野増"],
  "1000211": [13, "大島町", "差木地"],
  "1000212": [13, "大島町", "波浮港"],
  "1000301": [13, "利島村", "利島村"],
  "1000400": [13, "新島村", ""],
  "1000401": [13, "新島村", "若郷"],
  "1000402": [13, "新島村", "本村"],
  "1000511": [13, "新島村", "式根島"],
  "1000601": [13, "神津島村", "神津島村"],
  "1001100": [13, "三宅島三宅村", ""],
  "1001101": [13, "三宅島三宅村", "神着"],
  "1001102": [13, "三宅島三宅村", "伊豆"],
  "1001103": [13, "三宅島三宅村", "伊ケ谷"],
  "1001211": [13, "三宅島三宅村", "坪田"],
  "1001212": [13, "三宅島三宅村", "阿古"],
  "1001213": [13, "三宅島三宅村", "雄山"],
  "1001301": [13, "御蔵島村", "御蔵島村"],
  "1001400": [13, "八丈島八丈町", ""],
  "1001401": [13, "八丈島八丈町", "大賀郷"],
  "1001492": [13, "八丈島八丈町", "大賀郷", "２４６６－２"],
  "1001498": [13, "八丈島八丈町", "大賀郷", "２５５１番地２"],
  "1001511": [13, "八丈島八丈町", "三根"],
  "1001621": [13, "八丈島八丈町", "樫立"],
  "1001622": [13, "八丈島八丈町", "末吉"],
  "1001623": [13, "八丈島八丈町", "中之郷"],
  "1001701": [13, "青ヶ島村", "青ヶ島村"],
  "1002100": [13, "小笠原村", ""],
  "1002101": [13, "小笠原村", "父島"],
  "1002211": [13, "小笠原村", "母島"],
  "1006001": [13, "千代田区", "霞が関霞が関ビル１階"],
  "1006002": [13, "千代田区", "霞が関霞が関ビル２階"],
  "1006003": [13, "千代田区", "霞が関霞が関ビル３階"],
  "1006004": [13, "千代田区", "霞が関霞が関ビル４階"],
  "1006005": [13, "千代田区", "霞が関霞が関ビル５階"],
  "1006006": [13, "千代田区", "霞が関霞が関ビル６階"],
  "1006007": [13, "千代田区", "霞が関霞が関ビル７階"],
  "1006008": [13, "千代田区", "霞が関霞が関ビル８階"],
  "1006009": [13, "千代田区", "霞が関霞が関ビル９階"],
  "1006010": [13, "千代田区", "霞が関霞が関ビル１０階"],
  "1006011": [13, "千代田区", "霞が関霞が関ビル１１階"],
  "1006012": [13, "千代田区", "霞が関霞が関ビル１２階"],
  "1006013": [13, "千代田区", "霞が関霞が関ビル１３階"],
  "1006014": [13, "千代田区", "霞が関霞が関ビル１４階"],
  "1006015": [13, "千代田区", "霞が関霞が関ビル１５階"],
  "1006016": [13, "千代田区", "霞が関霞が関ビル１６階"],
  "1006017": [13, "千代田区", "霞が関霞が関ビル１７階"],
  "1006018": [13, "千代田区", "霞が関霞が関ビル１８階"],
  "1006019": [13, "千代田区", "霞が関霞が関ビル１９階"],
  "1006020": [13, "千代田区", "霞が関霞が関ビル２０階"],
  "1006021": [13, "千代田区", "霞が関霞が関ビル２１階"],
  "1006022": [13, "千代田区", "霞が関霞が関ビル２２階"],
  "1006023": [13, "千代田区", "霞が関霞が関ビル２３階"],
  "1006024": [13, "千代田区", "霞が関霞が関ビル２４階"],
  "1006025": [13, "千代田区", "霞が関霞が関ビル２５階"],
  "1006026": [13, "千代田区", "霞が関霞が関ビル２６階"],
  "1006027": [13, "千代田区", "霞が関霞が関ビル２７階"],
  "1006028": [13, "千代田区", "霞が関霞が関ビル２８階"],
  "1006029": [13, "千代田区", "霞が関霞が関ビル２９階"],
  "1006030": [13, "千代田区", "霞が関霞が関ビル３０階"],
  "1006031": [13, "千代田区", "霞が関霞が関ビル３１階"],
  "1006032": [13, "千代田区", "霞が関霞が関ビル３２階"],
  "1006033": [13, "千代田区", "霞が関霞が関ビル３３階"],
  "1006034": [13, "千代田区", "霞が関霞が関ビル３４階"],
  "1006035": [13, "千代田区", "霞が関霞が関ビル３５階"],
  "1006036": [13, "千代田区", "霞が関霞が関ビル３６階"],
  "1006051": [13, "千代田区", "霞が関", "３－２－５霞が関ビル２３階"],
  "1006066": [13, "千代田区", "霞が関", "３丁目２－５霞ヶ関ビル６階"],
  "1006080": [
    13,
    "千代田区",
    "霞が関",
    "３丁目２－５霞ヶ関ビル１６、２０～２５階",
  ],
  "1006090": [13, "千代田区", "霞が関霞が関ビル"],
  "1006101": [13, "千代田区", "永田町山王パークタワー１階"],
  "1006102": [13, "千代田区", "永田町山王パークタワー２階"],
  "1006103": [13, "千代田区", "永田町山王パークタワー３階"],
  "1006104": [13, "千代田区", "永田町山王パークタワー４階"],
  "1006105": [13, "千代田区", "永田町山王パークタワー５階"],
  "1006106": [13, "千代田区", "永田町山王パークタワー６階"],
  "1006107": [13, "千代田区", "永田町山王パークタワー７階"],
  "1006108": [13, "千代田区", "永田町山王パークタワー８階"],
  "1006109": [13, "千代田区", "永田町山王パークタワー９階"],
  "1006110": [13, "千代田区", "永田町山王パークタワー１０階"],
  "1006111": [13, "千代田区", "永田町山王パークタワー１１階"],
  "1006112": [13, "千代田区", "永田町山王パークタワー１２階"],
  "1006113": [13, "千代田区", "永田町山王パークタワー１３階"],
  "1006114": [13, "千代田区", "永田町山王パークタワー１４階"],
  "1006115": [13, "千代田区", "永田町山王パークタワー１５階"],
  "1006116": [13, "千代田区", "永田町山王パークタワー１６階"],
  "1006117": [13, "千代田区", "永田町山王パークタワー１７階"],
  "1006118": [13, "千代田区", "永田町山王パークタワー１８階"],
  "1006119": [13, "千代田区", "永田町山王パークタワー１９階"],
  "1006120": [13, "千代田区", "永田町山王パークタワー２０階"],
  "1006121": [13, "千代田区", "永田町山王パークタワー２１階"],
  "1006122": [13, "千代田区", "永田町山王パークタワー２２階"],
  "1006123": [13, "千代田区", "永田町山王パークタワー２３階"],
  "1006124": [13, "千代田区", "永田町山王パークタワー２４階"],
  "1006125": [13, "千代田区", "永田町山王パークタワー２５階"],
  "1006126": [13, "千代田区", "永田町山王パークタワー２６階"],
  "1006127": [13, "千代田区", "永田町山王パークタワー２７階"],
  "1006128": [13, "千代田区", "永田町山王パークタワー２８階"],
  "1006129": [13, "千代田区", "永田町山王パークタワー２９階"],
  "1006130": [13, "千代田区", "永田町山王パークタワー３０階"],
  "1006131": [13, "千代田区", "永田町山王パークタワー３１階"],
  "1006132": [13, "千代田区", "永田町山王パークタワー３２階"],
  "1006133": [13, "千代田区", "永田町山王パークタワー３３階"],
  "1006134": [13, "千代田区", "永田町山王パークタワー３４階"],
  "1006135": [13, "千代田区", "永田町山王パークタワー３５階"],
  "1006136": [13, "千代田区", "永田町山王パークタワー３６階"],
  "1006137": [13, "千代田区", "永田町山王パークタワー３７階"],
  "1006138": [13, "千代田区", "永田町山王パークタワー３８階"],
  "1006139": [13, "千代田区", "永田町山王パークタワー３９階"],
  "1006140": [13, "千代田区", "永田町山王パークタワー４０階"],
  "1006141": [13, "千代田区", "永田町山王パークタワー４１階"],
  "1006142": [13, "千代田区", "永田町山王パークタワー４２階"],
  "1006143": [13, "千代田区", "永田町山王パークタワー４３階"],
  "1006144": [13, "千代田区", "永田町山王パークタワー４４階"],
  "1006150": [13, "千代田区", "永田町", "２丁目１１－１"],
  "1006151": [13, "千代田区", "永田町", "２丁目１１－１山王パークタワー"],
  "1006152": [13, "千代田区", "永田町", "２丁目１１－１山王パークタワー"],
  "1006162": [13, "千代田区", "永田町", "２－１１－１山王パークタワー３階"],
  "1006170": [13, "千代田区", "永田町", "２丁目１１－１山王パークタワー"],
  "1006171": [13, "千代田区", "永田町", "２丁目１１－１山王パークタワー"],
  "1006172": [13, "千代田区", "永田町", "２丁目１１－１山王パークタワー"],
  "1006173": [13, "千代田区", "永田町", "２丁目１１－１山王パークタワー"],
  "1006174": [13, "千代田区", "永田町", "２丁目１１－１山王パークタワー"],
  "1006175": [13, "千代田区", "永田町", "２丁目１１－１山王パークタワー"],
  "1006177": [13, "千代田区", "永田町", "２丁目１１－１山王パークタワー"],
  "1006190": [13, "千代田区", "永田町山王パークタワー"],
  "1006201": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内１階",
  ],
  "1006202": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内２階",
  ],
  "1006203": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内３階",
  ],
  "1006204": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内４階",
  ],
  "1006205": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内５階",
  ],
  "1006206": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内６階",
  ],
  "1006207": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内７階",
  ],
  "1006208": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内８階",
  ],
  "1006209": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内９階",
  ],
  "1006210": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内１０階",
  ],
  "1006211": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内１１階",
  ],
  "1006212": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内１２階",
  ],
  "1006213": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内１３階",
  ],
  "1006214": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内１４階",
  ],
  "1006215": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内１５階",
  ],
  "1006216": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内１６階",
  ],
  "1006217": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内１７階",
  ],
  "1006218": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内１８階",
  ],
  "1006219": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内１９階",
  ],
  "1006220": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内２０階",
  ],
  "1006221": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内２１階",
  ],
  "1006222": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内２２階",
  ],
  "1006223": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内２３階",
  ],
  "1006224": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内２４階",
  ],
  "1006225": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内２５階",
  ],
  "1006226": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内２６階",
  ],
  "1006227": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内２７階",
  ],
  "1006228": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内２８階",
  ],
  "1006229": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内２９階",
  ],
  "1006230": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内３０階",
  ],
  "1006231": [
    13,
    "千代田区",
    "丸の内パシフィックセンチュリープレイス丸の内３１階",
  ],
  "1006255": [
    13,
    "千代田区",
    "丸の内",
    "１丁目１１－１パシフィックセンチュリープレイス丸の内２４階",
  ],
  "1006260": [13, "千代田区", "丸の内", "１丁目１１－１"],
  "1006262": [
    13,
    "千代田区",
    "丸の内",
    "１丁目１１－１パシフィックセンチュリープレイス丸の内地下１階",
  ],
  "1006277": [
    13,
    "千代田区",
    "丸の内",
    "１丁目１１－１パシフィックセンチュリープレイス丸の内２階",
  ],
  "1006290": [13, "千代田区", "丸の内パシフィックセンチュリープレイス丸の内"],
  "1006301": [13, "千代田区", "丸の内丸の内ビルディング１階"],
  "1006302": [13, "千代田区", "丸の内丸の内ビルディング２階"],
  "1006303": [13, "千代田区", "丸の内丸の内ビルディング３階"],
  "1006304": [13, "千代田区", "丸の内丸の内ビルディング４階"],
  "1006305": [13, "千代田区", "丸の内丸の内ビルディング５階"],
  "1006306": [13, "千代田区", "丸の内丸の内ビルディング６階"],
  "1006307": [13, "千代田区", "丸の内丸の内ビルディング７階"],
  "1006308": [13, "千代田区", "丸の内丸の内ビルディング８階"],
  "1006309": [13, "千代田区", "丸の内丸の内ビルディング９階"],
  "1006310": [13, "千代田区", "丸の内丸の内ビルディング１０階"],
  "1006311": [13, "千代田区", "丸の内丸の内ビルディング１１階"],
  "1006312": [13, "千代田区", "丸の内丸の内ビルディング１２階"],
  "1006313": [13, "千代田区", "丸の内丸の内ビルディング１３階"],
  "1006314": [13, "千代田区", "丸の内丸の内ビルディング１４階"],
  "1006315": [13, "千代田区", "丸の内丸の内ビルディング１５階"],
  "1006316": [13, "千代田区", "丸の内丸の内ビルディング１６階"],
  "1006317": [13, "千代田区", "丸の内丸の内ビルディング１７階"],
  "1006318": [13, "千代田区", "丸の内丸の内ビルディング１８階"],
  "1006319": [13, "千代田区", "丸の内丸の内ビルディング１９階"],
  "1006320": [13, "千代田区", "丸の内丸の内ビルディング２０階"],
  "1006321": [13, "千代田区", "丸の内丸の内ビルディング２１階"],
  "1006322": [13, "千代田区", "丸の内丸の内ビルディング２２階"],
  "1006323": [13, "千代田区", "丸の内丸の内ビルディング２３階"],
  "1006324": [13, "千代田区", "丸の内丸の内ビルディング２４階"],
  "1006325": [13, "千代田区", "丸の内丸の内ビルディング２５階"],
  "1006326": [13, "千代田区", "丸の内丸の内ビルディング２６階"],
  "1006327": [13, "千代田区", "丸の内丸の内ビルディング２７階"],
  "1006328": [13, "千代田区", "丸の内丸の内ビルディング２８階"],
  "1006329": [13, "千代田区", "丸の内丸の内ビルディング２９階"],
  "1006330": [13, "千代田区", "丸の内丸の内ビルディング３０階"],
  "1006331": [13, "千代田区", "丸の内丸の内ビルディング３１階"],
  "1006332": [13, "千代田区", "丸の内丸の内ビルディング３２階"],
  "1006333": [13, "千代田区", "丸の内丸の内ビルディング３３階"],
  "1006334": [13, "千代田区", "丸の内丸の内ビルディング３４階"],
  "1006335": [13, "千代田区", "丸の内丸の内ビルディング３５階"],
  "1006336": [13, "千代田区", "丸の内丸の内ビルディング３６階"],
  "1006337": [13, "千代田区", "丸の内丸の内ビルディング３７階"],
  "1006390": [13, "千代田区", "丸の内丸の内ビルディング"],
  "1006401": [13, "千代田区", "丸の内東京ビルディング１階"],
  "1006402": [13, "千代田区", "丸の内東京ビルディング２階"],
  "1006403": [13, "千代田区", "丸の内東京ビルディング３階"],
  "1006404": [13, "千代田区", "丸の内東京ビルディング４階"],
  "1006405": [13, "千代田区", "丸の内東京ビルディング５階"],
  "1006406": [13, "千代田区", "丸の内東京ビルディング６階"],
  "1006407": [13, "千代田区", "丸の内東京ビルディング７階"],
  "1006408": [13, "千代田区", "丸の内東京ビルディング８階"],
  "1006409": [13, "千代田区", "丸の内東京ビルディング９階"],
  "1006410": [13, "千代田区", "丸の内東京ビルディング１０階"],
  "1006411": [13, "千代田区", "丸の内東京ビルディング１１階"],
  "1006412": [13, "千代田区", "丸の内東京ビルディング１２階"],
  "1006413": [13, "千代田区", "丸の内東京ビルディング１３階"],
  "1006414": [13, "千代田区", "丸の内東京ビルディング１４階"],
  "1006415": [13, "千代田区", "丸の内東京ビルディング１５階"],
  "1006416": [13, "千代田区", "丸の内東京ビルディング１６階"],
  "1006417": [13, "千代田区", "丸の内東京ビルディング１７階"],
  "1006418": [13, "千代田区", "丸の内東京ビルディング１８階"],
  "1006419": [13, "千代田区", "丸の内東京ビルディング１９階"],
  "1006420": [13, "千代田区", "丸の内東京ビルディング２０階"],
  "1006421": [13, "千代田区", "丸の内東京ビルディング２１階"],
  "1006422": [13, "千代田区", "丸の内東京ビルディング２２階"],
  "1006423": [13, "千代田区", "丸の内東京ビルディング２３階"],
  "1006424": [13, "千代田区", "丸の内東京ビルディング２４階"],
  "1006425": [13, "千代田区", "丸の内東京ビルディング２５階"],
  "1006426": [13, "千代田区", "丸の内東京ビルディング２６階"],
  "1006427": [13, "千代田区", "丸の内東京ビルディング２７階"],
  "1006428": [13, "千代田区", "丸の内東京ビルディング２８階"],
  "1006429": [13, "千代田区", "丸の内東京ビルディング２９階"],
  "1006430": [13, "千代田区", "丸の内東京ビルディング３０階"],
  "1006431": [13, "千代田区", "丸の内東京ビルディング３１階"],
  "1006432": [13, "千代田区", "丸の内東京ビルディング３２階"],
  "1006433": [13, "千代田区", "丸の内東京ビルディング３３階"],
  "1006490": [13, "千代田区", "丸の内東京ビルディング"],
  "1006501": [13, "千代田区", "丸の内新丸の内ビルディング１階"],
  "1006502": [13, "千代田区", "丸の内新丸の内ビルディング２階"],
  "1006503": [13, "千代田区", "丸の内新丸の内ビルディング３階"],
  "1006504": [13, "千代田区", "丸の内新丸の内ビルディング４階"],
  "1006505": [13, "千代田区", "丸の内新丸の内ビルディング５階"],
  "1006506": [13, "千代田区", "丸の内新丸の内ビルディング６階"],
  "1006507": [13, "千代田区", "丸の内新丸の内ビルディング７階"],
  "1006508": [13, "千代田区", "丸の内新丸の内ビルディング８階"],
  "1006509": [13, "千代田区", "丸の内新丸の内ビルディング９階"],
  "1006510": [13, "千代田区", "丸の内新丸の内ビルディング１０階"],
  "1006511": [13, "千代田区", "丸の内新丸の内ビルディング１１階"],
  "1006512": [13, "千代田区", "丸の内新丸の内ビルディング１２階"],
  "1006513": [13, "千代田区", "丸の内新丸の内ビルディング１３階"],
  "1006514": [13, "千代田区", "丸の内新丸の内ビルディング１４階"],
  "1006515": [13, "千代田区", "丸の内新丸の内ビルディング１５階"],
  "1006516": [13, "千代田区", "丸の内新丸の内ビルディング１６階"],
  "1006517": [13, "千代田区", "丸の内新丸の内ビルディング１７階"],
  "1006518": [13, "千代田区", "丸の内新丸の内ビルディング１８階"],
  "1006519": [13, "千代田区", "丸の内新丸の内ビルディング１９階"],
  "1006520": [13, "千代田区", "丸の内新丸の内ビルディング２０階"],
  "1006521": [13, "千代田区", "丸の内新丸の内ビルディング２１階"],
  "1006522": [13, "千代田区", "丸の内新丸の内ビルディング２２階"],
  "1006523": [13, "千代田区", "丸の内新丸の内ビルディング２３階"],
  "1006524": [13, "千代田区", "丸の内新丸の内ビルディング２４階"],
  "1006525": [13, "千代田区", "丸の内新丸の内ビルディング２５階"],
  "1006526": [13, "千代田区", "丸の内新丸の内ビルディング２６階"],
  "1006527": [13, "千代田区", "丸の内新丸の内ビルディング２７階"],
  "1006528": [13, "千代田区", "丸の内新丸の内ビルディング２８階"],
  "1006529": [13, "千代田区", "丸の内新丸の内ビルディング２９階"],
  "1006530": [13, "千代田区", "丸の内新丸の内ビルディング３０階"],
  "1006531": [13, "千代田区", "丸の内新丸の内ビルディング３１階"],
  "1006532": [13, "千代田区", "丸の内新丸の内ビルディング３２階"],
  "1006533": [13, "千代田区", "丸の内新丸の内ビルディング３３階"],
  "1006534": [13, "千代田区", "丸の内新丸の内ビルディング３４階"],
  "1006535": [13, "千代田区", "丸の内新丸の内ビルディング３５階"],
  "1006536": [13, "千代田区", "丸の内新丸の内ビルディング３６階"],
  "1006537": [13, "千代田区", "丸の内新丸の内ビルディング３７階"],
  "1006538": [13, "千代田区", "丸の内新丸の内ビルディング３８階"],
  "1006590": [13, "千代田区", "丸の内新丸の内ビルディング"],
  "1006601": [13, "千代田区", "丸の内グラントウキョウサウスタワー１階"],
  "1006602": [13, "千代田区", "丸の内グラントウキョウサウスタワー２階"],
  "1006603": [13, "千代田区", "丸の内グラントウキョウサウスタワー３階"],
  "1006604": [13, "千代田区", "丸の内グラントウキョウサウスタワー４階"],
  "1006605": [13, "千代田区", "丸の内グラントウキョウサウスタワー５階"],
  "1006606": [13, "千代田区", "丸の内グラントウキョウサウスタワー６階"],
  "1006607": [13, "千代田区", "丸の内グラントウキョウサウスタワー７階"],
  "1006608": [13, "千代田区", "丸の内グラントウキョウサウスタワー８階"],
  "1006609": [13, "千代田区", "丸の内グラントウキョウサウスタワー９階"],
  "1006610": [13, "千代田区", "丸の内グラントウキョウサウスタワー１０階"],
  "1006611": [13, "千代田区", "丸の内グラントウキョウサウスタワー１１階"],
  "1006612": [13, "千代田区", "丸の内グラントウキョウサウスタワー１２階"],
  "1006613": [13, "千代田区", "丸の内グラントウキョウサウスタワー１３階"],
  "1006614": [13, "千代田区", "丸の内グラントウキョウサウスタワー１４階"],
  "1006615": [13, "千代田区", "丸の内グラントウキョウサウスタワー１５階"],
  "1006616": [13, "千代田区", "丸の内グラントウキョウサウスタワー１６階"],
  "1006617": [13, "千代田区", "丸の内グラントウキョウサウスタワー１７階"],
  "1006618": [13, "千代田区", "丸の内グラントウキョウサウスタワー１８階"],
  "1006619": [13, "千代田区", "丸の内グラントウキョウサウスタワー１９階"],
  "1006620": [13, "千代田区", "丸の内グラントウキョウサウスタワー２０階"],
  "1006621": [13, "千代田区", "丸の内グラントウキョウサウスタワー２１階"],
  "1006622": [13, "千代田区", "丸の内グラントウキョウサウスタワー２２階"],
  "1006623": [13, "千代田区", "丸の内グラントウキョウサウスタワー２３階"],
  "1006624": [13, "千代田区", "丸の内グラントウキョウサウスタワー２４階"],
  "1006625": [13, "千代田区", "丸の内グラントウキョウサウスタワー２５階"],
  "1006626": [13, "千代田区", "丸の内グラントウキョウサウスタワー２６階"],
  "1006627": [13, "千代田区", "丸の内グラントウキョウサウスタワー２７階"],
  "1006628": [13, "千代田区", "丸の内グラントウキョウサウスタワー２８階"],
  "1006629": [13, "千代田区", "丸の内グラントウキョウサウスタワー２９階"],
  "1006630": [13, "千代田区", "丸の内グラントウキョウサウスタワー３０階"],
  "1006631": [13, "千代田区", "丸の内グラントウキョウサウスタワー３１階"],
  "1006632": [13, "千代田区", "丸の内グラントウキョウサウスタワー３２階"],
  "1006633": [13, "千代田区", "丸の内グラントウキョウサウスタワー３３階"],
  "1006634": [13, "千代田区", "丸の内グラントウキョウサウスタワー３４階"],
  "1006635": [13, "千代田区", "丸の内グラントウキョウサウスタワー３５階"],
  "1006636": [13, "千代田区", "丸の内グラントウキョウサウスタワー３６階"],
  "1006637": [13, "千代田区", "丸の内グラントウキョウサウスタワー３７階"],
  "1006638": [13, "千代田区", "丸の内グラントウキョウサウスタワー３８階"],
  "1006639": [13, "千代田区", "丸の内グラントウキョウサウスタワー３９階"],
  "1006640": [13, "千代田区", "丸の内グラントウキョウサウスタワー４０階"],
  "1006641": [13, "千代田区", "丸の内グラントウキョウサウスタワー４１階"],
  "1006642": [13, "千代田区", "丸の内グラントウキョウサウスタワー４２階"],
  "1006690": [13, "千代田区", "丸の内グラントウキョウサウスタワー"],
  "1006701": [13, "千代田区", "丸の内グラントウキョウノースタワー１階"],
  "1006702": [13, "千代田区", "丸の内グラントウキョウノースタワー２階"],
  "1006703": [13, "千代田区", "丸の内グラントウキョウノースタワー３階"],
  "1006704": [13, "千代田区", "丸の内グラントウキョウノースタワー４階"],
  "1006705": [13, "千代田区", "丸の内グラントウキョウノースタワー５階"],
  "1006706": [13, "千代田区", "丸の内グラントウキョウノースタワー６階"],
  "1006707": [13, "千代田区", "丸の内グラントウキョウノースタワー７階"],
  "1006708": [13, "千代田区", "丸の内グラントウキョウノースタワー８階"],
  "1006709": [13, "千代田区", "丸の内グラントウキョウノースタワー９階"],
  "1006710": [13, "千代田区", "丸の内グラントウキョウノースタワー１０階"],
  "1006711": [13, "千代田区", "丸の内グラントウキョウノースタワー１１階"],
  "1006712": [13, "千代田区", "丸の内グラントウキョウノースタワー１２階"],
  "1006713": [13, "千代田区", "丸の内グラントウキョウノースタワー１３階"],
  "1006714": [13, "千代田区", "丸の内グラントウキョウノースタワー１４階"],
  "1006715": [13, "千代田区", "丸の内グラントウキョウノースタワー１５階"],
  "1006716": [13, "千代田区", "丸の内グラントウキョウノースタワー１６階"],
  "1006717": [13, "千代田区", "丸の内グラントウキョウノースタワー１７階"],
  "1006718": [13, "千代田区", "丸の内グラントウキョウノースタワー１８階"],
  "1006719": [13, "千代田区", "丸の内グラントウキョウノースタワー１９階"],
  "1006720": [13, "千代田区", "丸の内グラントウキョウノースタワー２０階"],
  "1006721": [13, "千代田区", "丸の内グラントウキョウノースタワー２１階"],
  "1006722": [13, "千代田区", "丸の内グラントウキョウノースタワー２２階"],
  "1006723": [13, "千代田区", "丸の内グラントウキョウノースタワー２３階"],
  "1006724": [13, "千代田区", "丸の内グラントウキョウノースタワー２４階"],
  "1006725": [13, "千代田区", "丸の内グラントウキョウノースタワー２５階"],
  "1006726": [13, "千代田区", "丸の内グラントウキョウノースタワー２６階"],
  "1006727": [13, "千代田区", "丸の内グラントウキョウノースタワー２７階"],
  "1006728": [13, "千代田区", "丸の内グラントウキョウノースタワー２８階"],
  "1006729": [13, "千代田区", "丸の内グラントウキョウノースタワー２９階"],
  "1006730": [13, "千代田区", "丸の内グラントウキョウノースタワー３０階"],
  "1006731": [13, "千代田区", "丸の内グラントウキョウノースタワー３１階"],
  "1006732": [13, "千代田区", "丸の内グラントウキョウノースタワー３２階"],
  "1006733": [13, "千代田区", "丸の内グラントウキョウノースタワー３３階"],
  "1006734": [13, "千代田区", "丸の内グラントウキョウノースタワー３４階"],
  "1006735": [13, "千代田区", "丸の内グラントウキョウノースタワー３５階"],
  "1006736": [13, "千代田区", "丸の内グラントウキョウノースタワー３６階"],
  "1006737": [13, "千代田区", "丸の内グラントウキョウノースタワー３７階"],
  "1006738": [13, "千代田区", "丸の内グラントウキョウノースタワー３８階"],
  "1006739": [13, "千代田区", "丸の内グラントウキョウノースタワー３９階"],
  "1006740": [13, "千代田区", "丸の内グラントウキョウノースタワー４０階"],
  "1006741": [13, "千代田区", "丸の内グラントウキョウノースタワー４１階"],
  "1006742": [13, "千代田区", "丸の内グラントウキョウノースタワー４２階"],
  "1006743": [13, "千代田区", "丸の内グラントウキョウノースタワー４３階"],
  "1006751": [13, "千代田区", "丸の内", "１丁目９－１"],
  "1006752": [13, "千代田区", "丸の内", "１丁目９－１"],
  "1006753": [13, "千代田区", "丸の内", "１－９－１"],
  "1006754": [13, "千代田区", "丸の内", "１丁目９－１"],
  "1006755": [13, "千代田区", "丸の内", "１丁目９－１"],
  "1006756": [13, "千代田区", "丸の内", "１丁目９－１"],
  "1006790": [13, "千代田区", "丸の内グラントウキョウノースタワー"],
  "1006801": [13, "千代田区", "大手町ＪＡビル１階"],
  "1006802": [13, "千代田区", "大手町ＪＡビル２階"],
  "1006803": [13, "千代田区", "大手町ＪＡビル３階"],
  "1006804": [13, "千代田区", "大手町ＪＡビル４階"],
  "1006805": [13, "千代田区", "大手町ＪＡビル５階"],
  "1006806": [13, "千代田区", "大手町ＪＡビル６階"],
  "1006807": [13, "千代田区", "大手町ＪＡビル７階"],
  "1006808": [13, "千代田区", "大手町ＪＡビル８階"],
  "1006809": [13, "千代田区", "大手町ＪＡビル９階"],
  "1006810": [13, "千代田区", "大手町ＪＡビル１０階"],
  "1006811": [13, "千代田区", "大手町ＪＡビル１１階"],
  "1006812": [13, "千代田区", "大手町ＪＡビル１２階"],
  "1006813": [13, "千代田区", "大手町ＪＡビル１３階"],
  "1006814": [13, "千代田区", "大手町ＪＡビル１４階"],
  "1006815": [13, "千代田区", "大手町ＪＡビル１５階"],
  "1006816": [13, "千代田区", "大手町ＪＡビル１６階"],
  "1006817": [13, "千代田区", "大手町ＪＡビル１７階"],
  "1006818": [13, "千代田区", "大手町ＪＡビル１８階"],
  "1006819": [13, "千代田区", "大手町ＪＡビル１９階"],
  "1006820": [13, "千代田区", "大手町ＪＡビル２０階"],
  "1006821": [13, "千代田区", "大手町ＪＡビル２１階"],
  "1006822": [13, "千代田区", "大手町ＪＡビル２２階"],
  "1006823": [13, "千代田区", "大手町ＪＡビル２３階"],
  "1006824": [13, "千代田区", "大手町ＪＡビル２４階"],
  "1006825": [13, "千代田区", "大手町ＪＡビル２５階"],
  "1006826": [13, "千代田区", "大手町ＪＡビル２６階"],
  "1006827": [13, "千代田区", "大手町ＪＡビル２７階"],
  "1006828": [13, "千代田区", "大手町ＪＡビル２８階"],
  "1006829": [13, "千代田区", "大手町ＪＡビル２９階"],
  "1006830": [13, "千代田区", "大手町ＪＡビル３０階"],
  "1006831": [13, "千代田区", "大手町ＪＡビル３１階"],
  "1006832": [13, "千代田区", "大手町ＪＡビル３２階"],
  "1006833": [13, "千代田区", "大手町ＪＡビル３３階"],
  "1006834": [13, "千代田区", "大手町ＪＡビル３４階"],
  "1006835": [13, "千代田区", "大手町ＪＡビル３５階"],
  "1006836": [13, "千代田区", "大手町ＪＡビル３６階"],
  "1006837": [13, "千代田区", "大手町ＪＡビル３７階"],
  "1006890": [13, "千代田区", "大手町ＪＡビル"],
  "1006901": [13, "千代田区", "丸の内丸の内パークビルディング１階"],
  "1006902": [13, "千代田区", "丸の内丸の内パークビルディング２階"],
  "1006903": [13, "千代田区", "丸の内丸の内パークビルディング３階"],
  "1006904": [13, "千代田区", "丸の内丸の内パークビルディング４階"],
  "1006905": [13, "千代田区", "丸の内丸の内パークビルディング５階"],
  "1006906": [13, "千代田区", "丸の内丸の内パークビルディング６階"],
  "1006907": [13, "千代田区", "丸の内丸の内パークビルディング７階"],
  "1006908": [13, "千代田区", "丸の内丸の内パークビルディング８階"],
  "1006909": [13, "千代田区", "丸の内丸の内パークビルディング９階"],
  "1006910": [13, "千代田区", "丸の内丸の内パークビルディング１０階"],
  "1006911": [13, "千代田区", "丸の内丸の内パークビルディング１１階"],
  "1006912": [13, "千代田区", "丸の内丸の内パークビルディング１２階"],
  "1006913": [13, "千代田区", "丸の内丸の内パークビルディング１３階"],
  "1006914": [13, "千代田区", "丸の内丸の内パークビルディング１４階"],
  "1006915": [13, "千代田区", "丸の内丸の内パークビルディング１５階"],
  "1006916": [13, "千代田区", "丸の内丸の内パークビルディング１６階"],
  "1006917": [13, "千代田区", "丸の内丸の内パークビルディング１７階"],
  "1006918": [13, "千代田区", "丸の内丸の内パークビルディング１８階"],
  "1006919": [13, "千代田区", "丸の内丸の内パークビルディング１９階"],
  "1006920": [13, "千代田区", "丸の内丸の内パークビルディング２０階"],
  "1006921": [13, "千代田区", "丸の内丸の内パークビルディング２１階"],
  "1006922": [13, "千代田区", "丸の内丸の内パークビルディング２２階"],
  "1006923": [13, "千代田区", "丸の内丸の内パークビルディング２３階"],
  "1006924": [13, "千代田区", "丸の内丸の内パークビルディング２４階"],
  "1006925": [13, "千代田区", "丸の内丸の内パークビルディング２５階"],
  "1006926": [13, "千代田区", "丸の内丸の内パークビルディング２６階"],
  "1006927": [13, "千代田区", "丸の内丸の内パークビルディング２７階"],
  "1006928": [13, "千代田区", "丸の内丸の内パークビルディング２８階"],
  "1006929": [13, "千代田区", "丸の内丸の内パークビルディング２９階"],
  "1006930": [13, "千代田区", "丸の内丸の内パークビルディング３０階"],
  "1006931": [13, "千代田区", "丸の内丸の内パークビルディング３１階"],
  "1006932": [13, "千代田区", "丸の内丸の内パークビルディング３２階"],
  "1006933": [13, "千代田区", "丸の内丸の内パークビルディング３３階"],
  "1006934": [13, "千代田区", "丸の内丸の内パークビルディング３４階"],
  "1006990": [13, "千代田区", "丸の内丸の内パークビルディング"],
  "1007001": [13, "千代田区", "丸の内ＪＰタワー１階"],
  "1007002": [13, "千代田区", "丸の内ＪＰタワー２階"],
  "1007003": [13, "千代田区", "丸の内ＪＰタワー３階"],
  "1007004": [13, "千代田区", "丸の内ＪＰタワー４階"],
  "1007005": [13, "千代田区", "丸の内ＪＰタワー５階"],
  "1007006": [13, "千代田区", "丸の内ＪＰタワー６階"],
  "1007007": [13, "千代田区", "丸の内ＪＰタワー７階"],
  "1007008": [13, "千代田区", "丸の内ＪＰタワー８階"],
  "1007009": [13, "千代田区", "丸の内ＪＰタワー９階"],
  "1007010": [13, "千代田区", "丸の内ＪＰタワー１０階"],
  "1007011": [13, "千代田区", "丸の内ＪＰタワー１１階"],
  "1007012": [13, "千代田区", "丸の内ＪＰタワー１２階"],
  "1007013": [13, "千代田区", "丸の内ＪＰタワー１３階"],
  "1007014": [13, "千代田区", "丸の内ＪＰタワー１４階"],
  "1007015": [13, "千代田区", "丸の内ＪＰタワー１５階"],
  "1007016": [13, "千代田区", "丸の内ＪＰタワー１６階"],
  "1007017": [13, "千代田区", "丸の内ＪＰタワー１７階"],
  "1007018": [13, "千代田区", "丸の内ＪＰタワー１８階"],
  "1007019": [13, "千代田区", "丸の内ＪＰタワー１９階"],
  "1007020": [13, "千代田区", "丸の内ＪＰタワー２０階"],
  "1007021": [13, "千代田区", "丸の内ＪＰタワー２１階"],
  "1007022": [13, "千代田区", "丸の内ＪＰタワー２２階"],
  "1007023": [13, "千代田区", "丸の内ＪＰタワー２３階"],
  "1007024": [13, "千代田区", "丸の内ＪＰタワー２４階"],
  "1007025": [13, "千代田区", "丸の内ＪＰタワー２５階"],
  "1007026": [13, "千代田区", "丸の内ＪＰタワー２６階"],
  "1007027": [13, "千代田区", "丸の内ＪＰタワー２７階"],
  "1007028": [13, "千代田区", "丸の内ＪＰタワー２８階"],
  "1007029": [13, "千代田区", "丸の内ＪＰタワー２９階"],
  "1007030": [13, "千代田区", "丸の内ＪＰタワー３０階"],
  "1007031": [13, "千代田区", "丸の内ＪＰタワー３１階"],
  "1007032": [13, "千代田区", "丸の内ＪＰタワー３２階"],
  "1007033": [13, "千代田区", "丸の内ＪＰタワー３３階"],
  "1007034": [13, "千代田区", "丸の内ＪＰタワー３４階"],
  "1007035": [13, "千代田区", "丸の内ＪＰタワー３５階"],
  "1007036": [13, "千代田区", "丸の内ＪＰタワー３６階"],
  "1007037": [13, "千代田区", "丸の内ＪＰタワー３７階"],
  "1007038": [13, "千代田区", "丸の内ＪＰタワー３８階"],
  "1007090": [13, "千代田区", "丸の内ＪＰタワー"],
  "1008019": [
    13,
    "千代田区",
    "大手町",
    "２－３－１大手町プレイスウエストタワー",
  ],
  "1008050": [13, "千代田区", "大手町", "二丁目６番４号常盤橋タワー"],
  "1008051": [13, "千代田区", "一ツ橋", "１丁目１－１"],
  "1008055": [13, "千代田区", "大手町", "１－７－１"],
  "1008065": [13, "千代田区", "大手町", "１－３－７"],
  "1008066": [13, "千代田区", "大手町", "１丁目３－７"],
  "1008070": [
    13,
    "千代田区",
    "大手町",
    "１丁目９番５号大手町フィナンシャルシティ・ノースタワー",
  ],
  "1008077": [13, "千代田区", "大手町", "１丁目７－２"],
  "1008078": [13, "千代田区", "大手町", "１丁目７－２"],
  "1008079": [13, "千代田区", "大手町", "１丁目７－２"],
  "1008080": [13, "千代田区", "大手町", "１－７－１（読売新聞ビル内）"],
  "1008086": [13, "千代田区", "丸の内", "２丁目３－１"],
  "1008088": [13, "千代田区", "大手町", "１－４－２"],
  "1008101": [
    13,
    "千代田区",
    "大手町",
    "２丁目３番２号大手町プレイスイーストタワー１１Ｆ",
  ],
  "1008102": [13, "千代田区", "大手町", "１丁目３－３"],
  "1008103": [13, "千代田区", "大手町", "２丁目６－１"],
  "1008104": [
    13,
    "千代田区",
    "大手町",
    "１－９－７大手町フィナンシャルシティサウスタワー",
  ],
  "1008105": [13, "千代田区", "大手町", "２丁目２－１（新大手町ビル）"],
  "1008107": [13, "千代田区", "大手町", "１丁目５番１号"],
  "1008108": [
    13,
    "千代田区",
    "大手町",
    "１丁目５－１大手町ファーストスクエアウエストタワー",
  ],
  "1008109": [
    13,
    "千代田区",
    "大手町",
    "１－９－７大手町フィナンシャルシティサウスタワー",
  ],
  "1008110": [13, "千代田区", "大手町", "１丁目７－２"],
  "1008111": [13, "千代田区", "千代田", "１－１"],
  "1008113": [13, "千代田区", "大手町", "２丁目２－１"],
  "1008117": [13, "千代田区", "丸の内", "３丁目２番３号丸の内二重橋ビル２２階"],
  "1008118": [13, "千代田区", "大手町", "１丁目３－２経団連会館"],
  "1008119": [13, "千代田区", "大手町", "１丁目３－５"],
  "1008120": [13, "千代田区", "大手町", "１丁目８－３"],
  "1008121": [13, "千代田区", "一ツ橋", "１丁目１－１"],
  "1008123": [13, "千代田区", "大手町", "２－１－１"],
  "1008124": [13, "千代田区", "大手町", "１－１－２大手門タワー"],
  "1008125": [13, "千代田区", "大手町", "１丁目７－２"],
  "1008126": [13, "千代田区", "大手町", "１丁目６－１大手町ビル７Ｆ"],
  "1008127": [
    13,
    "千代田区",
    "大手町",
    "１丁目９番２号大手町フィナンシャルシティグランキューブ",
  ],
  "1008128": [13, "千代田区", "一ツ橋", "１丁目２－２竹橋ビル"],
  "1008130": [13, "千代田区", "大手町", "２丁目２－２"],
  "1008131": [13, "千代田区", "大手町", "１丁目２－３三井生命本社ビル７Ｆ"],
  "1008132": [
    13,
    "千代田区",
    "大手町",
    "１丁目１番１号大手町パークビルディング",
  ],
  "1008133": [13, "千代田区", "大手町", "１丁目１番１号"],
  "1008134": [13, "千代田区", "大手町", "２－６－２日本ビル９Ｆ"],
  "1008135": [13, "千代田区", "一ツ橋", "１－１－１パレスサイドビル"],
  "1008136": [13, "千代田区", "大手町", "１－１－１大手町パークビルディング"],
  "1008139": [13, "千代田区", "大手町", "２丁目３－１"],
  "1008140": [13, "千代田区", "大手町", "１丁目７－２"],
  "1008141": [13, "千代田区", "永田町", "２丁目１０番３号"],
  "1008142": [13, "千代田区", "大手町", "二丁目６番４号常盤橋タワー"],
  "1008144": [13, "千代田区", "大手町", "１丁目４番１号"],
  "1008150": [13, "千代田区", "丸の内", "１丁目６－５"],
  "1008151": [13, "千代田区", "大手町", "１丁目７－１読売新聞ビル"],
  "1008152": [13, "千代田区", "大手町", "１丁目７番１号読売新聞ビル"],
  "1008154": [
    13,
    "千代田区",
    "大手町",
    "２－３－２大手町プレイスイーストタワー１０階",
  ],
  "1008155": [13, "千代田区", "大手町", "１－２－１"],
  "1008158": [13, "千代田区", "一ツ橋", "１丁目２－２"],
  "1008159": [
    13,
    "千代田区",
    "大手町",
    "１丁目２番１号ＯｔｅｍａｃｈｉＯｎｅタワー",
  ],
  "1008160": [13, "千代田区", "大手町", "１丁目７－２"],
  "1008165": [13, "千代田区", "大手町", "２丁目２－１"],
  "1008166": [13, "千代田区", "大手町", "１丁目６－１"],
  "1008168": [13, "千代田区", "大手町", "２丁目６番３号"],
  "1008169": [13, "千代田区", "大手町", "２丁目６番３号"],
  "1008172": [
    13,
    "千代田区",
    "大手町",
    "１－９－７大手町フィナンシャルシティサウスタワー",
  ],
  "1008174": [13, "千代田区", "大手町", "１丁目９－２"],
  "1008176": [13, "千代田区", "大手町", "１丁目５番５号"],
  "1008178": [13, "千代田区", "大手町", "１－９－６"],
  "1008181": [13, "千代田区", "大手町", "２丁目６－１朝日東海ビル"],
  "1008182": [13, "千代田区", "大手町", "２丁目２－１"],
  "1008184": [
    13,
    "千代田区",
    "大手町",
    "１丁目３番３号大手町合同庁舎３号館３階",
  ],
  "1008185": [13, "千代田区", "大手町", "１丁目６番１号"],
  "1008186": [13, "千代田区", "大手町", "１丁目８－１"],
  "1008187": [13, "千代田区", "大手町", "１丁目３番２号経団連会館１９階"],
  "1008188": [13, "千代田区", "大手町", "１丁目３－２"],
  "1008189": [
    13,
    "千代田区",
    "大手町",
    "１丁目９番２号大手町フィナンシャルシティグランキューブ",
  ],
  "1008201": [13, "千代田区", "丸の内", "１丁目３－２"],
  "1008203": [13, "千代田区", "丸の内", "１丁目６－４"],
  "1008205": [13, "千代田区", "丸の内", "１－１－１"],
  "1008207": [13, "千代田区", "丸の内", "１－８－２"],
  "1008214": [13, "千代田区", "丸の内", "１丁目４－３"],
  "1008215": [13, "千代田区", "丸の内", "１丁目６－５"],
  "1008216": [13, "千代田区", "丸の内", "１丁目３－１"],
  "1008219": [13, "千代田区", "丸の内", "１丁目６－６"],
  "1008228": [13, "千代田区", "大手町", "２丁目６－４"],
  "1008232": [13, "千代田区", "丸の内", "１丁目６番１号"],
  "1008233": [13, "千代田区", "丸の内", "１丁目４番１号"],
  "1008234": [13, "千代田区", "丸の内", "１丁目８番３号"],
  "1008235": [13, "千代田区", "丸の内", "１－８－２鉄鋼ビル５Ｆ"],
  "1008241": [13, "千代田区", "丸の内", "１丁目３番地３号"],
  "1008245": [
    13,
    "千代田区",
    "丸の内",
    "１丁目３番２号（三井住友ファイナンス＆リース（株）内）",
  ],
  "1008246": [
    13,
    "千代田区",
    "丸の内",
    "１丁目６－２新丸の内センタービルディング１４階",
  ],
  "1008251": [13, "千代田区", "丸の内", "１－１－１パレスビル"],
  "1008260": [13, "千代田区", "丸の内", "１丁目６－５丸の内北口ビル"],
  "1008262": [13, "千代田区", "丸の内", "１丁目６－５"],
  "1008265": [13, "千代田区", "丸の内", "１丁目６－５"],
  "1008268": [13, "千代田区", "丸の内", "１丁目６－５"],
  "1008270": [13, "千代田区", "大手町", "１丁目３－２"],
  "1008282": [13, "千代田区", "丸の内", "１丁目８－２"],
  "1008283": [13, "千代田区", "丸の内", "１丁目８－３丸の内トラストタワー本館"],
  "1008285": [
    13,
    "千代田区",
    "丸の内",
    "１－８－１丸の内トラストタワーＮ館１６階",
  ],
  "1008287": [13, "千代田区", "丸の内", "１丁目３番２号"],
  "1008288": [13, "千代田区", "丸の内", "１丁目６－６"],
  "1008302": [13, "千代田区", "丸の内", "２丁目５－２"],
  "1008303": [13, "千代田区", "丸の内", "３丁目４－１"],
  "1008306": [13, "千代田区", "丸の内", "３－３－１新東京ビル５階"],
  "1008307": [13, "千代田区", "丸の内", "２丁目１－１"],
  "1008310": [13, "千代田区", "丸の内", "二丁目７番３号"],
  "1008311": [13, "千代田区", "丸の内", "３丁目３－１"],
  "1008321": [13, "千代田区", "大手町", "１丁目２－１"],
  "1008322": [13, "千代田区", "大手町", "２丁目６番４号"],
  "1008324": [13, "千代田区", "丸の内", "２丁目５－２"],
  "1008325": [13, "千代田区", "丸の内", "３－３－１"],
  "1008332": [13, "千代田区", "丸の内", "３丁目２番３号丸の内二重橋ビル"],
  "1008333": [
    13,
    "千代田区",
    "丸の内",
    "３－２－３丸の内二重橋ビルディング２０階",
  ],
  "1008335": [13, "千代田区", "丸の内", "２－７－３"],
  "1008338": [13, "千代田区", "丸の内", "３丁目３－１"],
  "1008341": [13, "千代田区", "丸の内", "３丁目４－１"],
  "1008345": [13, "千代田区", "丸の内", "２丁目３－２郵船ビル"],
  "1008350": [13, "千代田区", "丸の内", "１－８－３"],
  "1008351": [13, "千代田区", "丸の内", "３丁目３－１"],
  "1008355": [13, "千代田区", "丸の内", "３丁目３－１"],
  "1008370": [13, "千代田区", "大手町", "２丁目６番４号"],
  "1008380": [13, "千代田区", "丸の内", "３丁目２－２"],
  "1008383": [13, "千代田区", "丸の内", "１－６－１丸の内センタービル８階"],
  "1008388": [13, "千代田区", "丸の内", "２－７－１"],
  "1008402": [13, "千代田区", "有楽町", "１丁目７－１"],
  "1008403": [
    13,
    "千代田区",
    "有楽町",
    "一丁目１３番２号第一生命日比谷ファースト１５階",
  ],
  "1008405": [13, "千代田区", "丸の内", "一丁目５番１号"],
  "1008410": [13, "千代田区", "有楽町", "２丁目１０－１東京交通会館１階"],
  "1008411": [13, "千代田区", "有楽町", "１丁目１３－１"],
  "1008415": [
    13,
    "千代田区",
    "有楽町",
    "１丁目２－２東宝日比谷ビル１０Ｆ～１２Ｆ",
  ],
  "1008421": [13, "千代田区", "有楽町", "１丁目２－２東宝日比谷ビル５階"],
  "1008424": [13, "千代田区", "有楽町", "２丁目７－１有楽町駅前ビル"],
  "1008427": [13, "千代田区", "有楽町", "２丁目１０－１東京交通会館ビル９Ｆ"],
  "1008430": [13, "千代田区", "有楽町", "２丁目２－１ラクチョウビル"],
  "1008439": [13, "千代田区", "有楽町", "１丁目９－３"],
  "1008444": [13, "千代田区", "有楽町", "１丁目１－１"],
  "1008448": [13, "千代田区", "有楽町", "２丁目１０－１東京交通会館ビル９Ｆ"],
  "1008450": [13, "千代田区", "有楽町", "１－１－３東京宝塚ビル１５階"],
  "1008456": [13, "千代田区", "有楽町", "１丁目１－２"],
  "1008484": [13, "千代田区", "有楽町", "１丁目４－１"],
  "1008488": [13, "千代田区", "有楽町", "２丁目５－１"],
  "1008502": [13, "千代田区", "内幸町", "２丁目１－４日比谷中日ビル３階"],
  "1008503": [13, "千代田区", "内幸町", "二丁目２番２号"],
  "1008505": [13, "千代田区", "内幸町", "２丁目１－４"],
  "1008506": [13, "千代田区", "内幸町", "２丁目１－１"],
  "1008507": [13, "千代田区", "内幸町", "２丁目１－４"],
  "1008511": [13, "千代田区", "内幸町", "２丁目２－２"],
  "1008520": [13, "千代田区", "内幸町", "２丁目２－２"],
  "1008521": [13, "千代田区", "内幸町", "２丁目１番１号飯野ビルディング１２階"],
  "1008522": [13, "千代田区", "内幸町", "１丁目３－１"],
  "1008533": [13, "千代田区", "内幸町", "１丁目３番２号内幸町東急ビル８階"],
  "1008540": [13, "千代田区", "内幸町", "２－１－１"],
  "1008543": [13, "千代田区", "内幸町", "２丁目２－１"],
  "1008550": [13, "千代田区", "内幸町", "１丁目１－１"],
  "1008555": [13, "千代田区", "内幸町", "１丁目５－２"],
  "1008558": [13, "千代田区", "内幸町", "１丁目１－１"],
  "1008560": [13, "千代田区", "内幸町", "１－１－３"],
  "1008567": [13, "千代田区", "丸の内", "１丁目６－６"],
  "1008575": [13, "千代田区", "内幸町", "１丁目１－１"],
  "1008577": [13, "千代田区", "内幸町", "２丁目２番２号富国生命ビル１９階"],
  "1008585": [13, "千代田区", "霞が関", "３丁目２－１"],
  "1008588": [13, "千代田区", "内幸町", "１丁目３－２"],
  "1008601": [
    13,
    "千代田区",
    "大手町",
    "２－３－２（銀座郵便局私書箱第１２４号）",
  ],
  "1008602": [13, "港区", "台場", "２丁目３－２（銀座郵便局私書箱第７８７号）"],
  "1008606": [
    13,
    "中央区",
    "京橋",
    "３丁目５－７（銀座郵便局私書箱第２３１７号）",
  ],
  "1008610": [
    13,
    "千代田区",
    "神田小川町",
    "１丁目１１（銀座郵便局私書箱第７７７号）",
  ],
  "1008617": [
    13,
    "中央区",
    "銀座",
    "６丁目３－１２（銀座郵便局私書箱第６６６号）",
  ],
  "1008620": [
    11,
    "新座市",
    "野火止",
    "７丁目２１番３３号（銀座郵便局私書箱第５７９号）",
  ],
  "1008621": [
    13,
    "中央区",
    "築地",
    "１丁目５－４（銀座郵便局私書箱第３６６号）",
  ],
  "1008629": [
    13,
    "江戸川区",
    "篠崎町",
    "２丁目４０３－７（銀座郵便局私書箱第２１８号）",
  ],
  "1008631": [
    13,
    "千代田区",
    "大手町",
    "１丁目２－１（銀座郵便局私書箱第８２２号）",
  ],
  "1008639": [
    11,
    "川越市",
    "大字今福",
    "８２５－３メールハウス今福センター（銀座郵便局私書箱第８９９号）",
  ],
  "1008640": [
    13,
    "千代田区",
    "神田小川町",
    "１丁目１１ＤＭＳビル内（銀座郵便局私書箱第９９０号）",
  ],
  "1008644": [
    13,
    "千代田区",
    "大手町",
    "１丁目７－２（銀座郵便局私書箱第４３３号）",
  ],
  "1008649": [
    13,
    "千代田区",
    "大手町",
    "１丁目７－２（銀座郵便局私書箱第２４６号）",
  ],
  "1008655": [
    13,
    "中央区",
    "京橋",
    "２丁目１５－１（銀座郵便局私書箱第３１７号）",
  ],
  "1008663": [
    13,
    "中央区",
    "日本橋室町",
    "１丁目５－３（銀座郵便局私書箱第４８０号）",
  ],
  "1008665": [
    13,
    "千代田区",
    "大手町",
    "１丁目７－２（銀座郵便局私書箱第２２８８号）",
  ],
  "1008666": [
    13,
    "江東区",
    "越中島",
    "２丁目１－３０（銀座郵便局私書箱第２７７号）",
  ],
  "1008676": [
    13,
    "江戸川区",
    "春江町",
    "２丁目３１－１５（銀座郵便局私書箱第８３１号）",
  ],
  "1008677": [
    12,
    "千葉市花見川区",
    "宇那谷町",
    "１５０１－２（銀座郵便局私書箱第２０１１号）",
  ],
  "1008791": [13, "千代田区", "大手町", "２－３－１"],
  "1008792": [13, "千代田区", "大手町", "２－３－１"],
  "1008793": [13, "千代田区", "大手町", "２－３－１"],
  "1008794": [13, "千代田区", "大手町", "２－３－１"],
  "1008901": [13, "千代田区", "霞が関", "１丁目３－１"],
  "1008902": [13, "千代田区", "霞が関", "１丁目３－１"],
  "1008903": [13, "千代田区", "霞が関", "１丁目１－１"],
  "1008904": [13, "千代田区", "霞が関", "１丁目１－１"],
  "1008905": [13, "千代田区", "霞が関", "３丁目２－１合同庁舎７号館"],
  "1008907": [13, "千代田区", "霞が関", "１丁目２－１"],
  "1008909": [13, "千代田区", "永田町", "１丁目８－１"],
  "1008910": [13, "千代田区", "永田町", "１丁目１１－２３"],
  "1008912": [13, "千代田区", "霞が関", "１丁目３－１"],
  "1008913": [13, "千代田区", "霞が関", "１丁目２－３"],
  "1008914": [13, "千代田区", "永田町", "１丁目６－１"],
  "1008915": [13, "千代田区", "霞が関", "３丁目４－３"],
  "1008916": [13, "千代田区", "霞が関", "１丁目２－２"],
  "1008917": [13, "千代田区", "霞が関", "３丁目３－２新霞が関ビル５階"],
  "1008918": [13, "千代田区", "霞が関", "２丁目１－３"],
  "1008919": [13, "千代田区", "霞が関", "２丁目２－１"],
  "1008920": [13, "千代田区", "霞が関", "１丁目１－４"],
  "1008921": [13, "千代田区", "霞が関", "１丁目３－１"],
  "1008922": [13, "千代田区", "霞が関", "３丁目２－１合同庁舎７号館"],
  "1008924": [13, "千代田区", "永田町", "１丁目１０－１"],
  "1008926": [13, "千代田区", "霞が関", "２丁目１－２"],
  "1008927": [13, "千代田区", "霞が関", "２丁目１－２"],
  "1008929": [13, "千代田区", "霞が関", "２丁目１－１"],
  "1008930": [13, "千代田区", "霞が関", "１丁目４－１"],
  "1008931": [13, "千代田区", "霞が関", "１丁目３－１"],
  "1008932": [13, "千代田区", "霞が関", "３－１－１中央合同庁舎第４号館"],
  "1008933": [13, "千代田区", "霞が関", "１丁目１－４"],
  "1008937": [13, "千代田区", "霞が関", "３丁目２－６東京倶楽部ビルディング"],
  "1008940": [13, "千代田区", "霞が関", "３丁目１－１"],
  "1008941": [13, "千代田区", "霞が関", "３丁目２－２"],
  "1008945": [13, "千代田区", "霞が関", "１丁目２－２"],
  "1008950": [13, "千代田区", "霞が関", "１丁目２－１"],
  "1008951": [13, "千代田区", "霞が関", "３丁目２－２"],
  "1008952": [13, "千代田区", "霞が関", "１丁目２－１"],
  "1008953": [13, "千代田区", "永田町", "２丁目１３－１０"],
  "1008955": [13, "千代田区", "霞が関", "３－３－１尚友会館"],
  "1008956": [13, "千代田区", "霞が関", "１丁目１－２"],
  "1008958": [13, "千代田区", "霞が関", "３－１－１"],
  "1008959": [13, "千代田区", "霞が関", "３丁目２－２"],
  "1008960": [13, "千代田区", "永田町", "１丁目７－１"],
  "1008961": [13, "千代田区", "永田町", "１丁目７－１"],
  "1008962": [13, "千代田区", "永田町", "２丁目１－１"],
  "1008964": [13, "千代田区", "永田町", "２丁目１３番１０号"],
  "1008965": [13, "千代田区", "霞が関", "３丁目７－３"],
  "1008967": [13, "千代田区", "霞が関", "３丁目２－１合同庁舎７号館"],
  "1008968": [13, "千代田区", "永田町", "１丁目６－１"],
  "1008969": [13, "千代田区", "霞が関", "１丁目２－２"],
  "1008970": [13, "千代田区", "霞が関", "３丁目１－１"],
  "1008971": [13, "千代田区", "霞が関", "１丁目１－２"],
  "1008973": [13, "千代田区", "霞が関", "１－１－１"],
  "1008974": [13, "千代田区", "霞が関", "２丁目１－２"],
  "1008975": [13, "千代田区", "霞が関", "１丁目２－２"],
  "1008976": [13, "千代田区", "霞が関", "２－１－３"],
  "1008977": [13, "千代田区", "霞が関", "１丁目１－１"],
  "1008978": [13, "千代田区", "霞が関", "３丁目１－１"],
  "1008979": [13, "千代田区", "霞が関", "３丁目３－２"],
  "1008980": [13, "千代田区", "霞が関", "３丁目３－２"],
  "1008981": [13, "千代田区", "永田町", "２丁目２－１"],
  "1008982": [13, "千代田区", "永田町", "２丁目１－２"],
  "1008983": [13, "千代田区", "霞が関", "３丁目２番１号"],
  "1008986": [13, "千代田区", "霞が関", "１丁目３－１"],
  "1008987": [13, "千代田区", "霞が関", "１丁目１－１中央合同庁舎第６号館Ｂ棟"],
  "1008989": [13, "千代田区", "永田町", "１丁目６－１"],
  "1008994": [13, "千代田区", "丸の内", "２丁目７－２"],
  "1008996": [13, "千代田区", "丸の内", "２－７－２"],
};

export function fetchAddress(postalCode: string): Promise<Address | false> {
  return new Promise((resolve) => {
    const normalizedPostalCode = postalCode.replace(/[０-９]/g, (char) => {
      return String.fromCharCode(char.charCodeAt(0) - 65248);
    });

    const digits = normalizedPostalCode.match(/\d/g);
    if (!digits) {
      resolve(false); // Invalid postal code
      return;
    }

    const sanitizedPostalCode = digits.join("");

    // Check if postal code exists in the postalCodeData object
    if (sanitizedPostalCode in postalCodeData) {
      const data = postalCodeData[sanitizedPostalCode];
      resolve({
        k: data[0],
        region: regions[data[0]] ?? "",
        l: data[1],
        m: data[2],
        o: data[3] || "", // If no extended address, fallback to empty string
      });
    } else {
      resolve(false); // Postal code not found
    }
  });
}
